<header>
  <div class="header-content">
    <img class="logo" src="assets/images/soldo-logo-v2.png">
    <div class="right-column">
      <div class="lang-switch" data-cy="Header_LanguageSelector_LanguageMenu" [ngClass]="{'open': dropDownOpen}">

        <div class="lang" [attr.data-cy]="'Header_LanguageSelector_Button_'+lang" (click)="onLangButtonClicked($event)">
          <span>{{ lang | uppercase }}</span>
          <i class="md-icon" data-cy="Header_LanguageSelector_DropDown_Toggle">keyboard_arrow_down</i>
        </div>

        <div class="droplang" data-cy="Header_LanguageSelector_DropDown">
          <button *ngFor="let l of availableLanguages" (click)="switchLang(l)" [ngClass]="{'selected': lang == l}" [attr.data-cy]="'Header_LanguageSelector_Button_'+l">{{ l
            | uppercase }}</button>
        </div>

      </div>
    </div>
  </div>
</header>
