import { Component, Input } from '@angular/core';
@Component({
  selector: 'soldo-field-error',
  templateUrl: './soldo-field-error.component.html',
  styleUrls: ['./soldo-field-error.component.scss']
})
export class SoldoFieldErrorComponent {

  @Input() errorName: string;
  active: boolean = false;

  constructor() { }

}
