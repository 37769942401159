<div class="form-input" [attr.data-cy]="cypressSelector">

  <input-label *ngIf="label" [forControl]="fieldName" [required]="isMandatory">
    {{ label | translate }}
  </input-label>

  <div style="position:relative">
    <ng-content select="[input]"></ng-content>
    <ng-content select="[infoMessage]"></ng-content>
  </div>

  <div class="text-danger" *ngIf="this.control.touched">
    <ng-content select="[errorMessage]"></ng-content>
  </div>

</div>
