<div class="typeform-layout">

  <div class="navigation">
    <p>
      <span>{{ "NAV.PROGRESS_LABEL" | translate: { current: 2, total: 7 } }}</span>
      <span *ngIf="true">{{ "NAV.LEGAL_REP" | translate }}</span>
    </p>
    <div class="progress-bar-outer">
      <div class="progress-bar-fill" [style]="'width: '+(100*.2)+'%;'"></div>
    </div>
  </div>

  <h1>Forms</h1>

    <form [formGroup]="form">

      <soldo-field-layout
        [control]="form.controls['test']"
        fieldName="test"
        [required]="true"
        cypressSelector=""
        label="label textfield">
    
        <input input
          trim="all"
          type="text"
          name="test"
          [readOnly]="readonly"
          formControlName="test"
          placeholder="{{ 'placeholder' | translate }}">

        <ng-container errorMessage>
            <soldo-field-error error errorName="required">{{ 'REGISTRATION.SIGNUP_STEP.EMAIL_VERIFICATION.EMAIL_REQUIRED' | translate }}</soldo-field-error>
        </ng-container>
      </soldo-field-layout>

      <soldo-field-layout class="description-field"
        [control]="form.controls['textarea']"
        fieldName="textarea"
        [required]="true"
        cypressSelector="Company_TextInput_BusinessDescription"
        label="REGISTRATION.COMPANY_STEP.DESCRIPTION.DESC_LABEL">

        <textarea input
            name="textarea"
            formControlName="textarea"
            [readOnly]="readonly"
            type="text" class="form-control"
            placeholder=""></textarea>
        <ng-container errorMessage>
        <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
        </ng-container>
      </soldo-field-layout>
        
      <soldo-field-layout *ngIf="form.get('isFatcaSubject')"
        [control]="form.controls['isFatcaSubject']"
        fieldName="isFatcaSubject"
        [required]="true"
        cypressSelector="Contact_RadioButton_FatcaSubject"
        label="REGISTRATION.PERSON.FATCA_FIELD_QUESTION">

        <div input class="fatca-field-options">
            <fieldset id="{{'isFatcaSubject-' + componentId}}">
                <legend>-- (hidden) need this to group the choices when multiple admin forms are on screen --</legend>
                <div *ngIf="!readonly || form.controls['isFatcaSubject'].value === true" 
                      class="radio-choice" data-cy="Contact_RadioButton_FatcaSubject_YES">
                        <input id="{{ 'us-person-' + componentId }}" type="radio" [value]=true
                        formControlName="isFatcaSubject" >
                        <label for="{{ 'us-person-' + componentId }}" tabindex="0" (keydown)="onRadioChoiceLabel($event, true)">
                          <span>{{ 'REGISTRATION.PERSON.FATCA_YES_LABEL' | translate }}</span>
                        </label>
                </div>
                <div *ngIf="!readonly || form.controls['isFatcaSubject'].value === false" 
                      class="radio-choice" data-cy="Contact_RadioButton_FatcaSubject_NO">
                        <input id="{{ 'non-us-person-' + componentId }}" type="radio" [value]=false
                        formControlName="isFatcaSubject">
                        <label for="{{ 'non-us-person-' + componentId }}" tabindex="0" (keydown)="onRadioChoiceLabel($event, false)">
                          <span>{{ 'REGISTRATION.PERSON.FATCA_NO_LABEL' | translate }}</span>
                        </label>
                </div>
            </fieldset>
        </div>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <h3>CHECKBOX</h3>
    
    <div class="checkbox">
      <ng-container *ngFor="let useCase of useCases">

        <soldo-field-layout
          [control]="form.controls[useCase.label]"
          [fieldName]="useCase.label">

          <ng-container input>
              <input #input [id]="useCase.label" type="checkbox" [formControlName]="useCase.label"
                (change)="updateCheck(useCase, input.checked)">
              <label class="full-length-line" [for]="useCase.label">
                {{'REGISTRATION.EXPECTED_ACTIVITY_STEP.USE_CASE.USE_CASE_'+useCase.label | translate}}
              </label>
          </ng-container>
        </soldo-field-layout>
          
      </ng-container>

      <ng-container *ngIf="form.controls['description']">
        <div class="description_container">
          <div class="spacer"></div>
          <soldo-field-layout [required]="true" [control]="form.controls['description']" cypressSelector="Expected_RadioButton_UseCases_Description"
            label="REGISTRATION.EXPECTED_ACTIVITY_STEP.USE_CASE.OTHER_LABEL" fieldName="description">
            <textarea input name="description" formControlName="description" type="text" class="form-control"
              rows="5"></textarea>
            <ng-container errorMessage>
              <soldo-field-error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
            </ng-container>
          </soldo-field-layout>
        </div>
      </ng-container>
      
      <div class="text-danger mt-3 h-MD">
        <ng-container *ngIf="form.touched && !atLeastOneSelected()">
          <div>{{'ONE_OR_MORE_OPTIONS_REQUIRED' | translate}}</div>
        </ng-container>
      </div>
    </div>
    
    <h3>select citizenship</h3>
    <soldo-field-layout
      [control]="form.controls['citizenship']"
      fieldName="citizenship"
      [required]="!readonly"
      cypressSelector="Contact_NgSelect_Citizenship"
      label="REGISTRATION.PERSON.CITIZENSHIP_LABEL">

      <ng-select input *ngIf="!readonly"
        formControlName="citizenship"
        [items]="completeCountryList" [dropdownPosition]="'bottom'"
        notFoundText="{{'NO_ITEMS_FOUND' | translate }}"
        
        [clearable]=false bindLabel="name" bindValue="alpha_3"
        placeholder="{{ 'REGISTRATION.PERSON.CITIZENSHIP_PLACEHOLDER' | translate }}"
        name="citizenship">
      </ng-select>
      <ng-container *ngIf="readonly" input>
        <input class="form-control" type="text" name="citizenship_label" [value]="getCitizenshipLabel()" [readOnly]="readonly">
        <input type="hidden" formControlName="citizenship" name="citizenship">
      </ng-container>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>
    
    <h3>CHIPS</h3>

    <soldo-field-layout *ngIf="form.get('numberOfCards')"
      class="chips-radio"
      [control]="form.controls['numberOfCards']"
      fieldName="numberOfCards"
      [required]="true"
      label="REGISTRATION.EXPECTED_ACTIVITY_STEP.CARDS.LABEL"
      cypressSelector="Expected_RadioButton_NumberOfCards">

      <div input class="radio-main">
        <div class="radio-list radio-list-top">
          <div *ngFor="let i of [0,1,2,3,4]" class="radio-item"
            [attr.data-cy]="'Expected_RadioButton_NumberOfCards_'+numberOfCardsOptions[i].value">
            <input type="radio" formControlName="numberOfCards" [id]="'OPT'+i" [value]="numberOfCardsOptions[i].value" [readOnly]="readonly">
            <label [for]="'OPT'+i">{{ numberOfCardsOptions[i].label }}</label>
          </div>
        </div>
      </div>

      <ng-container errorMessage>
        <soldo-field-error error errorName="required">{{ 'REGISTRATION.EXPECTED_ACTIVITY_STEP.CARDS.RANGE_REQUIRED' | translate }}</soldo-field-error>
      </ng-container>
    </soldo-field-layout>

    <!-- CHIPS WITH FLAGS -->

    <soldo-field-layout
      class="chips-radio"
      [control]="form.controls['country']"
      fieldName="country"
      [required]="true"
      cypressSelector="Signup_RadioItems_CountryOfIncorporation"
      label="select country">

        <div input class="radio-main">
        <div class="radio-list radio-list-top">
            <div *ngFor="let country of visibleCountries" class="radio-item"
            [attr.data-cy]="'Signup_RadioItem_CountryOfIncorporation_'+country.alpha_3">
            <input type="radio" formControlName="country" [id]="country.alpha_3" [value]="country.alpha_3">
            <label [for]="country.alpha_3">
                <div class="country-flag-icon">
                <typeface-icon class="{{'round-flag-icon round-flag-' + country.alpha_2.toLowerCase()}}"
                    [style]="'background: url(/assets/round-flag-icons/' + country.alpha_2.toLowerCase() + '.svg);'"></typeface-icon>
                </div>
                <div class="country-name">{{ country.name | translate}}</div>
            </label>
            </div>
        </div>
        </div>

        <soldo-field-error errorMessage errorName="required">{{ 'REQUIRED' | translate }}</soldo-field-error>

      </soldo-field-layout>

    <h3>phone-input</h3>
    <soldo-field-layout
    [control]="form.controls['phone']"
    fieldName="phone"
    
    [required]="!readonly"
    cypressSelector="Contact_TextInput_MobileNumber"
    label="REGISTRATION.PERSON.PHONE_LABEL">

        <div input *ngIf="!readonly"
        class="input-group phone-input" formGroupName="phone"[ngClass]="{'ng-touched': (isErrorVisible(form.get('phone').get('number')) || isErrorVisible(form.get('phone').get('prefix'))), 'readonly': false}">
        <ng-select
            [items]="completeCountryList" [dropdownPosition]="'bottom'"
            notFoundText="{{'NO_ITEMS_FOUND' | translate }}" class="" (keydown)="onKeyDown($event)" [clearable]="false" [readonly]="readonly"
            bindLabel="icc" bindValue="icc" placeholder="{{ 'REGISTRATION.PERSON.ICC_PLACEHOLDER' | translate }}"
            name="prefix" formControlName="prefix" data-cy="MobileNumber_NgSelect_ICC">
            <ng-template ng-option-tmp let-item="item" let-index="index">
            <span class="icc">{{item.icc}}</span><span class="border-left">{{item.name}}</span>
            </ng-template>
        </ng-select>
        <input #mobileNumberTextField
            formControlName="number"
            type="text" class="form-control"  name="number"
            [readOnly]="readonly"
            required="true"
            placeholder="{{ 'REGISTRATION.PERSON.PHONE_PLACEHOLDER' | translate }}"
            data-cy="MobileNumber_TextInput_Number" />
        </div>

        
        <ng-container input formGroupName="phone" *ngIf="readonly">
          <input
            [value]="getPhoneValue()"
            type="text" class="form-control has-field-info"  name="number_label"
            readOnly />
          <input type="hidden" name="prefix" formControlName="prefix">
          <input type="hidden" name="number" formControlName="number">
        </ng-container>

        <soldo-field-info *ngIf="!readonly" infoMessage>{{ 'REGISTRATION.PERSON.PHONE_INFO' | translate }}</soldo-field-info> <!-- *ngIf="!readonly" //using false for test purposes -->
        <ng-container errorMessage>
            <soldo-field-error error errorName="number-required">{{ 'REQUIRED' | translate }}</soldo-field-error>
          </ng-container>
    </soldo-field-layout>

    <h3 class="mt-XXL">SEARCH OPTIONS</h3>

    <!-- FIRST SEARCH OPTION (country search)-->
    <soldo-field-layout
      [control]="form.controls['searchTerm']"
      fieldName="searchTerm"
      cypressSelector="Signup_TextInput_SearchTerm"
      label="{{ 'REGISTRATION.SIGNUP_STEP.COUNTRY.COUNTRY_LABEL' | translate }}">

      <div input class="searchTerm-input">
        <input type="text" name="searchTerm" formControlName="searchTerm" class="form-control"
        [readonly]="readonly"
          placeholder="{{ 'REGISTRATION.SIGNUP_STEP.COUNTRY.COUNTRY_PLACEHOLDER' | translate }}" />
        <typeface-icon>search</typeface-icon>
      </div>
      <soldo-field-error errorMessage errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value:7} }}</soldo-field-error>
    </soldo-field-layout>

    <!-- SECOND SEARCH OPTION (company search and address search)-->
    <div class="search-field input-group">
      <soldo-field-layout [control]="form.controls['companySearch']" fieldName="companySearch" [required]="true" label="">

        <div input class="search-field input-group">
          <input type="text" name="companySearch" formControlName="companySearch" class="form-control" [readOnly]="readonly" 
            placeholder="{{ 'placeholder'  | translate }}" />

          <button class="primary" type="button" [disabled]="buttonSearchDisable || readonly"
            value="{{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.SEARCH_BUTTON' | translate }}"
            data-cy="Signup_Button_CompanySearch_Search">
            {{ 'REGISTRATION.SIGNUP_STEP.COMPANY_SEARCH.SEARCH_BUTTON' | translate }}
          </button>
        </div>

        <ng-container errorMessage>
          <soldo-field-error errorMessage errorName="maxlength">{{ 'MAX_LENGHT' | translate : { value:7} }}</soldo-field-error>

          <soldo-field-error errorName="required">{{ 'REQUIRED'  | translate }}</soldo-field-error>
        
          </ng-container>
      </soldo-field-layout>

    </div>

  </form>

  <button class="link no-padding mt-XXL" (click)="setReadonlyInput()">SET READONLY {{!readonly}}</button>

  <div class="d-flex justify-between mt-XXL">
  <button (click)="disableInput()">{{disableInputLabel}} FORM</button>
  <button type="submit" class="primary" (click)="setErrorsInput()">{{setErrorInputLabel}} ERRORS</button>
  </div>
</div>