<h1 class="mb-0">Buttons</h1>

<div class="page-container">
    <div class="titles column mt-0">
        <span></span>
        <span>ENABLED</span>
        <span>DISABLED</span>
        <span>Loading & disabled</span>
    </div>
    <!--BUTTON PRIMARY-->

    <div class="column mt-0">
        <h2>Primary</h2>
        <button class="primary">PRIMARY</button>
        <button class="primary" disabled>PRIMARY</button>
        <button class="primary loading" disabled>PRIMARY</button>
    </div>
    <!--BUTTON DEFAULT-->
    <div class="column mt-0">
        <h2>Default</h2>
        <button>DEFAULT</button>
        <button disabled>DEFAULT</button>
        <button class="loading" disabled>DEFAULT</button>
    </div>
    <!--BUTTON LINK-->

    <div class="column mt-0">
        <h2>Link</h2>
        <button class="link">Button link</button>
        <button class="link" disabled>link</button>
        <button class="link loading" disabled>Button link</button>
    </div>
    <!--BUTTON LINK NO PADDING-->
    <div class="column mt-0">
        <h2>Link no padding</h2>
        <button class="link no-padding">link NO-PADDING</button>
        <button class="link no-padding" disabled>link NO-PADDING</button>
        <button class="link no-padding loading" disabled>link NO-PADDING</button>
    </div>
    <!--BUTTON LINK SMALL-->
    <div class="column mt-0">
        <h2>Link Small</h2>
        <button class="link caption">link</button>
        <button class="link caption" disabled>link</button>
        <button class="link caption loading" disabled>link</button>
    </div>

</div>
<h1>Buttons with icon</h1>
<div class="page-container">
    <div class="titles column mt-0">
        <span></span>
        <span>ENABLED</span>
        <span>DISABLED</span>
        <span>Loading & disabled</span>
    </div>
    <!--BUTTON PRIMARY-->
    <div class="column">
        <button class="primary">PRIMARY <typeface-icon>arrow_right_alt</typeface-icon></button>
        <button class="primary" disabled><typeface-icon class="md-icon rotate180">arrow_right_alt</typeface-icon>PRIMARY</button>
        <button class="primary loading" disabled>PRIMARY<typeface-icon>arrow_right_alt</typeface-icon></button>
    </div>
    <!--BUTTON DEFAULT-->
    <div class="column">
        <button>DEFAULT <typeface-icon>arrow_right_alt</typeface-icon></button>
        <button disabled>DEFAULT <typeface-icon>arrow_right_alt</typeface-icon></button>
        <button class="loading" disabled>DEFAULT<typeface-icon>arrow_right_alt</typeface-icon></button>
    </div>
    <!--BUTTON LINK-->
    <div class="column">
        <button class="link"><span>Button link</span> <typeface-icon>arrow_right_alt</typeface-icon></button>
        <button class="link" disabled><span>link</span> <typeface-icon>arrow_right_alt</typeface-icon></button>
        <button class="link loading" disabled>Button link<typeface-icon>arrow_right_alt</typeface-icon></button>
    </div>
    <!--BUTTON LINK NO PADDING-->
    <div class="column">
        <button class="link no-padding"><span>link NO-PADDING</span> <typeface-icon>arrow_right_alt</typeface-icon></button>
        <button class="link no-padding" disabled><span>link NO-PADDING</span> <typeface-icon>arrow_right_alt</typeface-icon></button>
        <button class="link no-padding loading" disabled>link NO-PADDING<typeface-icon>arrow_right_alt</typeface-icon></button>
    </div>
    <!--BUTTON LINK SMALL-->
    <div class="column">
        <button class="link caption"><span>link</span><typeface-icon>arrow_right_alt</typeface-icon></button>
        <button class="link caption" disabled><span>link</span><typeface-icon>arrow_right_alt</typeface-icon></button>
        <button class="link caption loading" disabled>link<typeface-icon>arrow_right_alt</typeface-icon></button>
    </div>
</div>

<h1>DESTRUCTIVE</h1>

<div class="page-container">
    <div class="titles column mt-0">
        <span></span>
        <span>ENABLED</span>
        <span>DISABLED</span>
        <span>Loading & disabled</span>
    </div>
    <!--BUTTON PRIMARY-->
    <div class="column">
        <button class="primary destructive">PRIMARY</button>
        <button class="primary destructive" disabled>PRIMARY</button>
        <button class="primary destructive loading" disabled>PRIMARY<typeface-icon>arrow_right_alt</typeface-icon></button>
    </div>
    <!--BUTTON DEFAULT-->
    <div class="column">
        <button class="destructive">DEFAULT</button>
        <button class="destructive" disabled>DEFAULT</button>
        <button class="destructive loading" disabled>DEFAULT<typeface-icon>arrow_right_alt</typeface-icon></button>
    </div>
    <!--BUTTON LINK-->
    <div class="column">
        <button class="link destructive">link</button>
        <button class="link destructive" disabled>link</button>
        <button class="link destructive loading" disabled>link<typeface-icon>arrow_right_alt</typeface-icon></button>
    </div>
    <!--BUTTON LINK NO PADDING-->
    <div class="column">
        <button class="no-padding destructive link">link NO-PADDING</button>
        <button class="no-padding destructive link" disabled>link NO-PADDING</button>
        <button class="no-padding destructive link loading" disabled>link NO-PADDING<typeface-icon>arrow_right_alt</typeface-icon></button>
    </div>
    <!--BUTTON LINK SMALL-->
    <div class="column">
        <button class="link destructive caption">link</button>
        <button class="link destructive caption" disabled>link</button>
        <button class="link destructive caption loading" disabled>link<typeface-icon>arrow_right_alt</typeface-icon></button>
    </div>
</div>